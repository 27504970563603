export const SearchIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1951 17.2053L13.4176 12.4277C14.343 11.2846 14.9002 9.83207 14.9002 8.25006C14.9002 4.58342 11.9167 1.59998 8.25009 1.59998C4.58345 1.59998 1.60004 4.58339 1.60004 8.25003C1.60004 11.9167 4.58348 14.9001 8.25012 14.9001C9.83213 14.9001 11.2847 14.3429 12.4278 13.4175L17.2053 18.1951C17.3418 18.3316 17.521 18.4002 17.7002 18.4002C17.8795 18.4002 18.0586 18.3316 18.1952 18.1951C18.4688 17.9214 18.4688 17.479 18.1951 17.2053ZM8.25012 13.5001C5.35488 13.5001 3.00006 11.1453 3.00006 8.25003C3.00006 5.35479 5.35488 2.99997 8.25012 2.99997C11.1454 2.99997 13.5002 5.35479 13.5002 8.25003C13.5002 11.1453 11.1453 13.5001 8.25012 13.5001Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0.4"
      />
    </svg>
  );
};
