export const UserIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
d="M10 1.66675C7.81389 1.66675 6.04169 3.43895 6.04169 5.62508C6.04169 7.81121 7.81389 9.58342 10 9.58342C12.1861 9.58342 13.9584 7.81121 13.9584 5.62508C13.9584 3.43895 12.1861 1.66675 10 1.66675Z" 
        fill="currentColor"
      />
      <path
d="M7.50002 10.8334C5.31389 10.8334 3.54169 12.6056 3.54169 14.7917C3.54169 16.9779 5.31389 18.7501 7.50002 18.7501H12.5C14.6861 18.7501 16.4584 16.9779 16.4584 14.7917C16.4584 12.6056 14.6861 10.8334 12.5 10.8334H7.50002Z" 
        fill="currentColor"
      />
    </svg>
  );
};
