export const ThemeDarkIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.0246 11.1731C17.8521 11.13 17.6795 11.1731 17.5285 11.281C16.9676 11.7556 16.3204 12.1439 15.6085 12.4028C14.9398 12.6616 14.2063 12.7911 13.4297 12.7911C11.6823 12.7911 10.086 12.0792 8.94261 10.9358C7.79927 9.79248 7.08738 8.19612 7.08738 6.44875C7.08738 5.71528 7.21681 5.00339 7.43254 4.35622C7.66984 3.6659 8.015 3.04029 8.46802 2.50098C8.66217 2.26368 8.61903 1.91852 8.38173 1.72437C8.23072 1.61651 8.05814 1.57336 7.88556 1.61651C6.0519 2.11268 4.45553 3.21287 3.31219 4.6798C2.21199 6.12516 1.56482 7.91568 1.56482 9.8572C1.56482 12.2086 2.51401 14.3443 4.06723 15.8975C5.62045 17.4507 7.73455 18.3999 10.1075 18.3999C12.0922 18.3999 13.9259 17.7096 15.3928 16.5662C16.8813 15.4013 17.9599 13.7403 18.4129 11.8419C18.4992 11.5399 18.3267 11.2378 18.0246 11.1731Z"
        fill="currentColor"
      />
    </svg>
  );
};
