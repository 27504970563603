'use client';
import React, { useEffect } from 'react';
import { getCartItems } from './cart-actions';

const USER_CART_DATA = 'user-cart';

const setLocalStoreCartData = (cartData) => {
  return localStorage.setItem(USER_CART_DATA, JSON.stringify(cartData));
};

const removeLocalStoreCartData = () => {
  return localStorage.removeItem(USER_CART_DATA);
};

export const getLocalStoreCartData = () => {
  const hasCartData = localStorage?.getItem(USER_CART_DATA) != 'undefined';

  return hasCartData ? JSON.parse(localStorage?.getItem(USER_CART_DATA)) : null;
};




const initialState = {
  products: [],
  totalProductsCount: 0,
  totalItemsCount: 0,
  subTotalPrice: 0,
  shippingTotalPrice: 0,
  totalProductsPrice: 0,
};

// cartReducer function
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'RELOAD_CART_FROM_DB':
      setLocalStoreCartData(action.payload);
      return action.payload;

    case 'RESET_CART':
      removeLocalStoreCartData();
      return initialState;

    default:
      return state;
  }
};

// create Context
export const CartContext = React.createContext();

export const CartProvider = (props) => {
  // const localStorage_user_cart = getLocalStoreCartData();
  const [cart, dispatch] = React.useReducer(cartReducer, initialState);

  useEffect(() => {
    const getExistingCart = async () => {
      const { data, error } = await getCartItems();
      dispatch({
        type: 'RELOAD_CART_FROM_DB',
        payload: data?.cart || initialState,
      });
    };
    getExistingCart();
  }, []);

  return (
    <CartContext.Provider value={[cart, dispatch]}>
      {props.children}
    </CartContext.Provider>
  );
};
