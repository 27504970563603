'use client';
import { Fragment, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { Dialog } from '@/app/components/ui/dialog';
import { Transition } from '@/app/components/ui/transition';
import { DRAWER_VIEW, useDrawer } from '@/app/components/drawer-views/context';
import { CloseIcon } from '../icons/close-icon';
const CartDrawerView = dynamic(
  () => import('@/app/components/cart/cart-drawer-view'),
);
const SidebarDrawerMobile = dynamic(
  () => import('@/app/components/layouts/_side-drawer-mobile'),
);

function renderDrawerContent(view: DRAWER_VIEW | string) {
  switch (view) {
    case 'MOBILE_MENU':
      return <SidebarDrawerMobile />;
    default:
      return <CartDrawerView />;
  }
}

export default function DrawersContainer() {
  const router = useRouter();
  const { view, isOpen, closeDrawer } = useDrawer();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeDrawer}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-1500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-1500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md bg-brand">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-0 flex  sm:-ml-[35px] h-28 sm:p-2 bg-brand  rounded-l-3xl">
                      <button
                        type="button"
                        className="relative rounded-sm text-gray-700 hover:text-black h-10 "
                        onClick={closeDrawer}
                      >
                        <CloseIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll pt-2 pb-6 mt-2 shadow-xl rounded-tl-xl dark:bg-dark-300 bg-light dark:text-light text-black">
                    {/* <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-bold leading-6  uppercase">
                        {title}
                      </Dialog.Title>
                    </div> */}
                    {/* Content */}
                    <div className="mt-6 px-2 sm:px-2 ">
                      {view && renderDrawerContent(view)}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={closeDrawer}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-1500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-1500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 cursor-pointer bg-dark bg-opacity-60 backdrop-blur transition-opacity dark:bg-opacity-80" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed inset-y-0 right-0 flex max-w-full">
            <div className="w-screen max-w-md">
              <div className="flex h-full flex-col bg-light shadow-xl dark:bg-dark-300">
                {view && renderDrawerContent(view)}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
