export const CartIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width={26}
      height={25}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.82347 5.68742C7.22182 2.63078 9.83515 0.270752 13.0001 0.270752C16.165 0.270752 18.7784 2.63086 19.1767 5.68742L19.8945 5.68742C20.9731 5.68738 21.8676 5.68736 22.5696 5.78544C23.3077 5.88856 23.9708 6.11625 24.4751 6.67972C24.9794 7.24318 25.1325 7.92741 25.1535 8.6723C25.1735 9.38083 25.0747 10.2698 24.9555 11.3419L24.6219 14.3439C24.3728 16.5867 24.177 18.3483 23.8446 19.7174C23.5039 21.1208 22.9971 22.216 22.0563 23.0581C21.1146 23.901 19.9646 24.2832 18.5245 24.4667C17.1184 24.6458 15.3329 24.6458 13.0581 24.6458H12.9421C10.6673 24.6458 8.88178 24.6458 7.47568 24.4667C6.03552 24.2832 4.88558 23.901 3.94386 23.0581C3.00304 22.216 2.49626 21.1208 2.15553 19.7174C1.82311 18.3483 1.6274 16.5868 1.37819 14.3438L1.04465 11.3419C0.925497 10.2699 0.826689 9.38085 0.846653 8.6723C0.867641 7.92741 1.0207 7.24318 1.52503 6.67972C2.02935 6.11625 2.6925 5.88856 3.43051 5.78544C4.13252 5.68736 5.02702 5.68738 6.10568 5.68742L6.82347 5.68742ZM17.5328 5.68742C17.1492 3.53229 15.2656 1.89575 13.0001 1.89575C10.7345 1.89575 8.85088 3.53236 8.4673 5.68742H17.5328Z"
        fill="currentColor"
        fillRule="evenodd" clipRule="evenodd"
      />
    </svg>
  );
};