'use client';
import Button from '@/app/components/ui/button';
import { SearchIcon } from '@/app/components/icons/search-icon';

export default function SearchButton() {
  return (
    <Button variant="icon" aria-label="Search" className="flex">
      <SearchIcon className="h-5 w-5" />
    </Button>
  );
}
