export const ThemeLightIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.545 6.45505C12.6455 5.55558 11.3757 4.97357 10 4.97357C8.62437 4.97357 7.35452 5.52913 6.45505 6.45505C5.55558 7.35452 4.97357 8.62437 4.97357 10C4.97357 11.3757 5.55558 12.6455 6.45505 13.545C7.35452 14.4445 8.62437 15.0265 10 15.0265C11.3757 15.0265 12.6455 14.4709 13.545 13.545C14.4445 12.6455 15.0265 11.3757 15.0265 10C15.0265 8.62437 14.4709 7.35452 13.545 6.45505Z"
        fill="currentColor"
      />
      <path
        d="M10 3.41269C10.3704 3.41269 10.6879 3.09523 10.6879 2.72486V0.68783C10.6879 0.31746 10.3704 0 10 0C9.62965 0 9.31219 0.31746 9.31219 0.68783V2.72486C9.31219 3.09523 9.62965 3.41269 10 3.41269Z"
        fill="currentColor"
      />
      <path
        d="M15.6349 5.34392L17.0899 3.88889C17.3545 3.62434 17.3545 3.20106 17.0899 2.93651C16.8254 2.67196 16.4021 2.67196 16.1376 2.93651L14.6825 4.39154C14.418 4.65609 14.418 5.07937 14.6825 5.34392C14.9206 5.60847 15.3439 5.60847 15.6349 5.34392Z"
        fill="currentColor"
      />
      <path
        d="M19.3122 9.31219H17.2751C16.9047 9.31219 16.5873 9.62965 16.5873 10C16.5873 10.3704 16.9047 10.6879 17.2751 10.6879H19.3122C19.6825 10.6879 20 10.3704 20 10C20 9.62965 19.6825 9.31219 19.3122 9.31219Z"
        fill="currentColor"
      />
      <path
        d="M15.6085 14.6561C15.3439 14.3916 14.9207 14.3916 14.6561 14.6561C14.3916 14.9207 14.3916 15.3439 14.6561 15.6085L16.1111 17.0635C16.3757 17.3281 16.799 17.3281 17.0635 17.0635C17.3281 16.799 17.3281 16.3757 17.0635 16.1111L15.6085 14.6561Z"
        fill="currentColor"
      />
      <path
        d="M10 16.5873C9.62965 16.5873 9.31219 16.9047 9.31219 17.2751V19.3122C9.31219 19.6825 9.62965 20 10 20C10.3704 20 10.6879 19.6825 10.6879 19.3122V17.2751C10.6879 16.9047 10.3704 16.5873 10 16.5873Z"
        fill="currentColor"
      />
      <path
        d="M4.36511 14.6561L2.91008 16.1111C2.64553 16.3757 2.64553 16.799 2.91008 17.0635C3.17463 17.3281 3.59791 17.3281 3.86246 17.0635L5.31749 15.6085C5.58204 15.3439 5.58204 14.9207 5.31749 14.6561C5.07939 14.3916 4.65611 14.3916 4.36511 14.6561Z"
        fill="currentColor"
      />
      <path
        d="M3.41269 10C3.41269 9.62965 3.09523 9.31219 2.72486 9.31219H0.68783C0.31746 9.31219 0 9.62965 0 10C0 10.3704 0.31746 10.6879 0.68783 10.6879H2.72486C3.09523 10.6879 3.41269 10.3704 3.41269 10Z"
        fill="currentColor"
      />
      <path
        d="M4.36511 5.34392C4.62966 5.60847 5.05294 5.60847 5.31749 5.34392C5.58204 5.07937 5.58204 4.65609 5.31749 4.39154L3.86246 2.93651C3.59791 2.67196 3.17463 2.67196 2.91008 2.93651C2.64553 3.20106 2.64553 3.62434 2.91008 3.88889L4.36511 5.34392Z"
        fill="currentColor"
      />
    </svg>
  );
};
