'use client';
import cn from 'classnames';

export default function Copyright({ className }: { className?: string }) {
  const currentYear = new Date().getFullYear();
  return (
    <div className={cn('tracking-[0.2px]', className)}>
      &copy; {'Copyright'} {currentYear} {'by'}{' '}
      <a
        href="https://www.allforgiveaways.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-brand-dark"
      >
        J&S Venture Capital Holdings Ltd
      </a>
      .
    </div>
  );
}
