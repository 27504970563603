import React from 'react';

export const NotificationIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      className="group hover:scale-105 transition-transform"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
  <path d="M5.37321 6.64118C5.63516 4.2836 7.62791 2.5 10 2.5C12.3721 2.5 14.3648 4.2836 14.6268 6.64118L14.8366 8.5297C14.9443 9.4985 15.2596 10.4327 15.7611 11.2685L16.2428 12.0714C16.5455 12.5759 16.6969 12.8281 16.7246 13.0315C16.7854 13.4772 16.5412 13.9087 16.1277 14.0858C15.939 14.1667 15.6449 14.1667 15.0565 14.1667H4.94353C4.35516 14.1667 4.06097 14.1667 3.87233 14.0858C3.45885 13.9087 3.21456 13.4772 3.27537 13.0315C3.30311 12.8281 3.45447 12.5759 3.75719 12.0714L4.23888 11.2685C4.74039 10.4327 5.05573 9.4985 5.16337 8.5297L5.37321 6.64118Z" fill="currentColor" />
  <path d="M7.5 14.1667C7.5 14.4951 7.56466 14.8201 7.6903 15.1235C7.81594 15.4268 8.00009 15.7024 8.23223 15.9345C8.46438 16.1667 8.73998 16.3508 9.04329 16.4764C9.34661 16.6021 9.6717 16.6667 10 16.6667C10.3283 16.6667 10.6534 16.6021 10.9567 16.4764C11.26 16.3508 11.5356 16.1667 11.7678 15.9345C11.9999 15.7024 12.1841 15.4268 12.3097 15.1235C12.4353 14.8201 12.5 14.4951 12.5 14.1667L10 14.1667H7.5Z" fill="currentColor" />
    </svg>
  );
};
